import { Typography } from "@mui/material";
import { QueryListOfTypes, QueryTypes } from "./constants";
import React from "react";
import { Link } from "@mui/material";

type Props = {
  type: QueryListOfTypes;
};

const TypeDescription = ({ type }: Props) => {
  const getText = (type: QueryListOfTypes) => {
    switch (type) {
      case QueryTypes.EASY_SEARCH:
        return (<>Designed to provide quick access to data, by filtering only on high-level categories, and pre-selecting some options to show. Samples are binned based on lithology (all samples, all shales, all carbonates) and can be filtered by age (Ma) and/or location. Individually select the geochemical analytes you want displayed. Key sample context information is pre-selected, with the option of adding other columns. This search averages all measurements for a given sample, converts oxides to elements (e.g. Al2O3 à Al), and standardizes units. It is not possible to sort/search by geochemical methodology. For more detailed user guides see <Link target="_blank" href="/documentation" >Documentation</Link>.</>);
      case QueryTypes.SAMPLES:
        return (<>Designed to provide fine-level control, with the ability to a) filter based on all sample characteristics (Sample Filters), geochemical values and methodologies (Analyte Filters), and then b) choose exactly what information will appear (Show). Note that you must positively select a feature for inclusion, even if a filter condition has been set. The search averages all measurements for a given sample, converts oxides to elements (e.g. Al2O3 à Al), and standardizes units. Note that methodology filters apply across all analytes. Choosing to show methods and references may result in a slower search. For more detailed user guides see <Link target="_blank" href="/documentation" >Documentation</Link>.</>);
      case QueryTypes.ANALYSES:
        return (<>Designed for exploring individual geochemical results, as originally published/provided – this search does not average results for a sample, convert oxides to elements (e.g. Al and Al2O3 are both present), or standardize units. This search also provides access to additional lab- and analysis-specific details. For more detailed user guides see <Link target="_blank" href="/documentation" >Documentation</Link>.</>)
      default:
        return null;
    }
  }
  return <Typography variant="caption" sx={{p:1}}>{getText(type)}</Typography>
}

export default TypeDescription;