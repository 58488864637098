
import React from "react";
import {useSearchContext} from "../store";
import Table from "../../Table/Table";


const TableSearchComponent = () => {
  const {
    searchStore: {
      data,
      column,
      totalPages,
      totalCount,
      query,
    }, callAPI: {
      onTablePageChange,
      onTablePageSizeChange,
      onSortedChange,
    }
  } = useSearchContext()

  return (
    <Table
      column={column}
      data={data}
      onPageChange={onTablePageChange}
      pages={totalPages}
      totalCount={totalCount}
      page={query.page}
      pageSize={query.count}
      onPageSizeChange={onTablePageSizeChange}
      sorted={query.sorted}
      onSortedChange={onSortedChange}
    />
  )
}

export default TableSearchComponent
