export enum EasySearchTypesEnum{
  SAMPLES = "All samples",
  SHALES = "Shales only",
  CARBONATES = "Carbonates only",
}

export const EasySearchTypesTooltipEnum: {[key: string]: string} = {
  [EasySearchTypesEnum.SHALES]: "Returns all ‘fine-grained siliciclastic’ samples (not specifically true shales). This includes argillite, clay, claystone, marl, marlstone, meta-argillite, metapelite, metasiltstone, mud, mudstone, oil shale, pelite, silt, siltite, siltstone and shale. It also includes samples with no coded lithology but with total aluminum > 3.4 weight percent.",
  [EasySearchTypesEnum.CARBONATES]: "Returns all carbonate samples. This includes lime mudstone, wackestone, packstone, grainstone, boundstone, crystalline limestone, dolomudstone, dolowackestone, dolopackstone, dolograinstone, crystalline dolomite, ooze, carbonate, limestone, dolomite, dolomicrite, limestone/dolomite. It also includes samples with no coded lithology but with total combined calcium and magnesium > 15.3 weight percent."
}
export enum BaseTypeEnum{
  SAMPLES = "samples",
  ANALYSES = "analyses",
  NHHXRF = "nhhxrf",
}

export enum EasySearchEnum{
  EASY_SEARCH = "easySearch",
}

export type QueryListOfTypes = BaseTypeEnum | EasySearchEnum
export const QueryTypes = {...BaseTypeEnum, ...EasySearchEnum}

export const DEFAULT_SORTED = [{id: "sample identifier", desc: false}];
export const HIDE_ALERT_TIMEOUT = 5000;
export const easySearchTypeValue = Object.values(EasySearchTypesEnum);

export const defaultEasySearchShow = [
  'section_name',
  'height_meters',
  'site_type',
  'country',
  'basin_type',
  'meta_bin',
  'strat_unit',
  'environment_bin',
  'geol_age',
  'lithology_name',
  'interpreted_age',
  'min_age',
  'max_age'
]

export const defaultSearchState = {
  data: [],
  query: {
    type: QueryTypes.SAMPLES,
    filters: {},
    show: [...defaultEasySearchShow],
    page: 0,
    count: 25,
    sorted: DEFAULT_SORTED,
  },
  column: [
    {
      columns: []
    }
  ],
  attributes: [],
  loading: false,
  totalPages: 1,
  totalCount: 0,
  downloading: false,
  mapDownloading: false,
  isEasySearchTab: false,
  tabType: QueryTypes.EASY_SEARCH,
  error: '',
  openMap: false,
  drawerOpen: false,
  mapData: [],
  mapDataLoading: false
};

