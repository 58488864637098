import React, { useEffect, useMemo, useState } from "react";
import { ClassNameMap, TableBody, TableCell, TableHead, TableRow, Theme } from "@mui/material";
import TablePagination from "@mui/material/TablePagination"
import { makeStyles } from "@mui/styles";
import { uniqueId } from "lodash";
import ReferencesCell from "../ReferencesCell";
import { Table as MuiTable } from '@mui/material';
import HeaderCell from "./HeaderCell";
import { SortOrder } from "src/common/types/sorting";
import PageSelector from "./PageSelector";
import NoRecords from "../NoRecords";
import { ColumnType, Columns, Row, SearchTableResponse, SortType } from "../SearchWithProvider/types";
import TableCustomRow from "./TableCustomRow";

type Props = {
  data: SearchTableResponse["rows"],
  column: ColumnType[],
  page: number,
  onPageChange: (page: number) => void,
  sorted: SortType[],
  onSortedChange: (id: string, desc: boolean) => void
  pageSize: number,
  onPageSizeChange: (value: string) => void,
  totalCount: number,
  pages: number
};

const rowsPerPageOptions = [5, 10, 20, 25, 50, 100];

const newColumnMap = (column: ColumnType[], classes: ClassNameMap<string>) => {
  let resColumns = column[0].columns;
  resColumns = resColumns.map((x: Columns) => ({
    ...x,
    // flex,
    className: 'data-cell',
    filterable: false,
    hideable: false,
    sortable: true,
    maxWidth: 500
  }));
  if (resColumns.length) {
    resColumns = [
      {
        className: classes.infoColumn,
        filterable: false,
        hideable: false,
        renderCell: ({ row, state, setState }: Row) => 
          <ReferencesCell original={row} state={state} setState={setState} />
      },
      ...resColumns
    ];
  }

  return resColumns.map((x) => ({
    ...x,
    field: x.accessor,
    headerName: x.Header
  }));
}

const Table = ({
  data,
  column,
  page,
  onPageChange,
  sorted,
  onSortedChange,
  pageSize,
  onPageSizeChange,
  totalCount,
  pages
}: Props) => {
  
  const classes = useStyles();
  const [rows, setRows] = useState<(Row & {id: string})[]>([]);
  const columns = useMemo(() => newColumnMap(column, classes), [column, classes]);

  useEffect(() => {
    if(!data.length) return;
    refreshRows();
  }, [data]);

  const refreshRows = () => {
    const mappedRows = data.map((x: Row): Row & {id: string} => ({
      ...x,
      id: uniqueId(),
    }));
    setRows(mappedRows);
  }

  const onToggleOrder = (id: string, sort: SortOrder) => {
    onSortedChange(id, sort === "desc" );
  }
  const handleChangePage = (_e: unknown, page: number) => {
    onPageChange(page);
  }

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onPageSizeChange(e.target.value);
  }

  let order: SortOrder | undefined = undefined;

  if (sorted[0]) {
    order = sorted[0].desc ? "desc" : "asc";
  }

  if(!data.length) return <NoRecords/>
  return (
    <div className={classes.wrapper}>
        <div className={classes.tableWrapper}>
          <MuiTable
            classes={{
              root: classes.table
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((x: any) => (
                  <HeaderCell
                    key={uniqueId(x.accessor)}
                    className={x.className}
                    id={x.accessor}
                    sortable={x.sortable}
                    sortBy={sorted[0]?.id}
                    order={order}
                    onToggleOrder={onToggleOrder}
                  >
                    {x.Header}
                  </HeaderCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: Row, i: number) => (
                  <TableCustomRow
                      className={i % 2 > 0 ? 'odd-row' : undefined}
                      columns={columns}
                      row={row}
                    />
              ))}
            </TableBody>
          </MuiTable>
        </div>
        <TablePagination
          labelDisplayedRows={() => (
            <PageSelector
              page={page}
              totalPages={pages}
              onPageChange={onPageChange}
            />
          )}
          component={'span'}
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handlePageSizeChange}
        />
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  tableWrapper: {
    flex: 1,
    backgroundColor: theme.palette.common.white,
    minHeight: 400,
    position: 'relative',
    overflow: 'auto'
  },
  table: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.grey[300],
      boxShadow: "0 2px 15px 0 rgb(0 0 0 / 15%)"
    },
    '& > .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      zIndex: 1
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    "& .MuiTableCell-root.data-cell": {
      maxWidth: 500
    },
    "& .MuiTableBody-root > .MuiTableRow-root.odd-row": {
      backgroundColor: theme.palette.grey[50]
    },
    "& .MuiTableBody-root > .MuiTableRow-root:hover:not(.sub-table)": {
      backgroundColor: theme.palette.action.hover
    }
  },
  infoColumn: {
    width: '0%',
    wordSpace: 'nowrap',
    padding: '0px 8px !important'
  }
}))

export default Table;
