import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    open: boolean;
    onClose: () => void;
    onDontShowAgain: () => void;
}

export const Phase2Alert = ({ open, onClose, onDontShowAgain }: Props) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Warning</DialogTitle>
            <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    The SGP Phase 2 data is currently in soft release. If you identify any issues with the search website, API, or underlying data please email Erik Sperling (<Link href="mailto:esper@stanford.edu">esper@stanford.edu</Link>).
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDontShowAgain}>Don't show again</Button>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}