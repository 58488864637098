import React, {ReactNode, useEffect, useRef, useState} from "react";
import isEqual from "lodash/isEqual";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  InputAdornment,
  TextField,
} from "@mui/material";
import {CONFIG_CONSTANTS} from "src/constants";
import axios from "axios";
import {useUpdatedEffect} from "src/common/hooks/useUpdatedEffect";

import "../styles/Select.css"
import AsyncMultiInfoLabel from "./AsyncMultiInfoLabel";
import {Option, QueryType, SearchContext, StringArrayFilters} from "./SearchWithProvider/types";
import {unionBy} from "lodash";
import { usePrevious } from "src/common/hooks/usePrevious";

type LoadingState = "pending" | "loading" | "loaded" | "failed";

type Props = {
  attribute: keyof StringArrayFilters,
  params?: {
    attribute?: string,
    current_search?: string,
    limit?: number,
    countries?: string[],
    loadEmpty?: boolean
  },
  label?: ReactNode,
  query: QueryType,
  tip?: string
  onChange: SearchContext["callAPI"]["constructMulti"],
  enableSelectAll?: boolean
}


const AsyncMulti = ({attribute, params, label, tip, query, onChange, enableSelectAll}: Props) => {
  const [loadingState, setLoadingState] = useState<LoadingState>("pending");
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const prevOpen = usePrevious(open);
  const abortController = useRef(new AbortController());

  const getOptions = async (inputValue: string): Promise<Option[]> => {
    let queryParams = {...params};
    if (!inputValue && attribute == "ana_code") {
      queryParams = {
        ...queryParams,
        limit: queryParams?.limit ?? CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
      };
    } else if (!inputValue) {
      queryParams = {
        ...queryParams,
        limit: queryParams?.limit ?? CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
      };
    } else {
      queryParams = {
        ...queryParams,
        limit: queryParams?.limit ?? CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
        current_search: inputValue
      };
    }

    const answer = await axios.post<{[K in keyof StringArrayFilters]: any}[]>("/api/v1/post/attr", queryParams, { signal: abortController.current.signal });

    return answer.data.map((option) => ({
      value: option[attribute],
      label: String(option[attribute])
    }));
  }

  const loadItems = async () => {
    if (params?.loadEmpty === false && !inputValue) {
      return;
    }

    setLoadingState("loading");
    try {
      abortController.current.abort();
      abortController.current = new AbortController();

      const newOptions: Option[] = await getOptions(inputValue);
      const mappedOptions = mapOptionsToSelected(newOptions);
      setOptions(mappedOptions);

      setLoadingState("loaded");
    } catch (e) {
      setLoadingState("failed")
    }
  }

  const mapOptionsToSelected = (options: Option[]) => {
    return options.map(x => {
      const selected = selectedOptions.find(y => x.value === y.value);
      if (selected) {
        return selected;
      }

      return x;
    })
  }

  const onInputChange = (
    _event: React.SyntheticEvent,
    value: string,
    _reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(value);
  };

  const onOpen = () => {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  }

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, selectedOptions: Option[], reason: string) => {

    if (reason === "selectOption" || reason === "removeOption") {
      const selectAllIndex = selectedOptions.findIndex(option => option.value === 'select-all')
      if (enableSelectAll && selectAllIndex !== -1) {
        selectedOptions.splice(selectAllIndex, 1);
        onChange(
          attribute,
          unionBy(selectedOptions, options, "value")
        );
      } else {
        return onChange(attribute, selectedOptions);
      }
    } else if (reason === "clear") {
      onChange(attribute, [])
    }
  };

  useEffect(() => {
    if (!open) {
      return
    }

    if (open !== prevOpen) {
      loadItems();
      return;
    }

    const loadItemsTimeout = setTimeout(() => loadItems(), 500);
    return () => clearTimeout(loadItemsTimeout);
  }, [open, inputValue]);

  useUpdatedEffect(([prevParams]) => {
    if (!prevParams) {
      return;
    }

    if (isEqual(params, prevParams)) {
      return;
    }

    setLoadingState("pending");
  }, [params]);

  useEffect(() => {
    setSelectedOptions(prev => {
      const newSelectedOptions = (query.filters[attribute] || []).map((x: string | number) => {
        const existingOption = options.find(y => x === y.value);
        if (existingOption) {
          return existingOption;
        }

        const existingSelectedOption = prev.find(y => x === y.value);
        if (existingSelectedOption) {
          return existingSelectedOption;
        }

        return {
          value: x,
          label: String(x)
        };
      });

      return newSelectedOptions;
    });
  }, [query.filters[attribute]]);

  const filterOptions = (options: Option[], { inputValue }: any) => {
    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (inputValue) {
      filteredOptions.sort((a, b) => {
        const startsWithInputA = a.label.toLowerCase().startsWith(inputValue.toLowerCase()) ? -1 : 1;
        const startsWithInputB = b.label.toLowerCase().startsWith(inputValue.toLowerCase()) ? -1 : 1;
        return startsWithInputA - startsWithInputB || a.label.localeCompare(b.label);
      });
    }

    if (enableSelectAll) filteredOptions.unshift({label: 'Select All', value: "select-all"});

    return filteredOptions;
  };
  return (
    <Autocomplete
      className="select"
      classes={{
        inputRoot: "select-root"
      }}
      multiple
      options={options}
      getOptionLabel={(option: Option) => option.label}
      loading={loadingState === "loading"}
      limitTags={4}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      onBlur={onClose}
      inputValue={inputValue}
      onInputChange={onInputChange}
      value={selectedOptions}
      onChange={handleChange}
      disableCloseOnSelect={false}
      filterSelectedOptions={true}
      filterOptions={filterOptions}
      ChipProps={{
        color: "primary"
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder="Select..."
          InputLabelProps={{
            unselectable: "off",
            "aria-disabled": "false"
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {!!tip && (
                  <InputAdornment position="end" className="select-tip">
                    <AsyncMultiInfoLabel
                      description={tip}
                    />
                  </InputAdornment>
                )}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AsyncMulti;
