import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router-dom';
import Navigation from './Navigation';
import Home from './Home';
import LicenseAndCitation from './LicenseAndCitation';
import WebSite from './WebSite';
import PublicAPIDescription from './PublicAPIDescription';
import SearchAttributes from './SearchAttributes';
import Video from './Video';
import ProxyPrimers from './ProxyPrimers';
import IndigenousDataGovernance from './IndigenousDataGovernance';


const Documentation: React.FC<RouteComponentProps> = ({ location }) => {
    return (
      <TransitionGroup className="outer-doc">
          <Navigation />
          <CSSTransition
            key={location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'documentation-content'}>
              <Switch>
                  <Route exact path="/documentation" component={Home} />
                  <Route exact path="/documentation/license-and-citation" component={LicenseAndCitation} />
                  <Route exact path="/documentation/web-site" component={WebSite} />
                  <Route exact path="/documentation/api-description" component={PublicAPIDescription} />
                  <Route exact path="/documentation/search-attributes" component={SearchAttributes} />
                  <Route exact path="/documentation/video" component={Video} />
                  <Route exact path="/documentation/proxy-primers" component={ProxyPrimers} />
                  <Route exact path="/documentation/indigenous-data-governance" component={IndigenousDataGovernance} />
              </Switch>
          </CSSTransition>
      </TransitionGroup>
    );
};

export default withRouter(Documentation);
