import * as React from 'react';
import "./../../../styles/FilterPanel.css";
import "./../../../styles/Select.css"
import TypeSelector from "../../FilterPanel/TypeSelector";
import DrawerPanel from "../../../common/components/DrawerPanel";
import FilterActions from "../../FilterPanel/FilterActions";

import {
  EasySearchTypesEnum,
  QueryTypes,
} from "../../FilterPanel/constants";

import {useSearchContext} from "../store";
import {useState} from "react";
import SampleFilterSection from "./SampleFilterSection/SampleFilterSection";
import ShowSection from "./ShowSection/ShowSection";
import EasySearchSection from "./EasySearchSection/EasySearchSection";
import AnalyteFiltersSections from "./AnalyteFiltersSections/AnalyteFiltersSections";
import TypeDescription from 'src/components/FilterPanel/TypeDescription';

const SideBar = () => {
  const [state, setState] = useState<{ variant: EasySearchTypesEnum }>({
    variant: EasySearchTypesEnum.SAMPLES
  })
  const {
    searchStore: {
      loading,
      tabType,
      drawerOpen
    },
    callAPI: {
      onSearchPress,
      clearAll,
      closeDrawer,
      constructEasySearchMode,
    }
  } = useSearchContext()

  const selectEasySearchMeta = (event: React.ChangeEvent<HTMLInputElement>) => {
    const variant = event.target.value as EasySearchTypesEnum;
    setState({ variant });
    constructEasySearchMode(variant );
  }

  const onChangeVariant = (event: React.SyntheticEvent) => {
    clearAll();
    selectEasySearchMeta(event as React.ChangeEvent<HTMLInputElement>)
  }

  return (
    <DrawerPanel
      open={drawerOpen}
      onClose={closeDrawer}
      footer={(
        <FilterActions
          loading={loading}
          onSearch={onSearchPress}
          onClear={clearAll}
        />
      )}
      header={<TypeSelector/>}
    >
      <>
        <TypeDescription type={tabType}/>
        {tabType === QueryTypes.EASY_SEARCH ? 
          <EasySearchSection variant={state.variant} onChangeVariant={onChangeVariant}/>
          :
          <SampleFilterSection/>
        }
        {tabType !== QueryTypes.EASY_SEARCH && (
          <AnalyteFiltersSections/>
        )}
        <ShowSection/>

      </>
    </DrawerPanel>
  );
}

export default SideBar;
