import React from "react";
import "./../styles/ReferencesCell.css";
import { IconButton } from '@mui/material';
import { InfoOutlined, KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import SampleDetails from "./SearchWithProvider/SampleDetails";
import { Row } from "./SearchWithProvider/types";

type Props = {
    original: Row,
    state: any
    setState: (prev: object) => any
}

const ReferencesCell = ({ original, state, setState }: Props) => {
    const toggleOpen = () => setState((prev: any) => ({
        ...prev,
        open: !prev.open
    }));
    
    return (
        <div className="reference-cell-container">
            {!!original.subRows && (
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={toggleOpen}
                    sx={{
                        padding: 0,
                        height: 30,
                        width: 30
                    }}
                >
                    {state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            )}
            <SampleDetails
                sampleId={original['sample identifier']}
            >
                <InfoOutlined fontSize="small" />
            </SampleDetails>
        </div>
    )
}

export default ReferencesCell;
