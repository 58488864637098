import React, {ReactElement} from "react";
import "src/styles/SampleDetails.css";
import {Tooltip, Typography} from "@mui/material";
import {SampleDetailsType} from "../types";
import {useSampleDetails} from "./common";

export type SampleDetailsProps = {
  sampleId: number;
  children: ReactElement;
  onClose?: () => void;
};

const SampleDetails = ({ sampleId, children }: SampleDetailsProps) => {
  const [{loading, sample, loadingDots}, loadData] = useSampleDetails();

  const dotsString = '.'.repeat(loadingDots);

  const afterShow = () => {
    loadData(sampleId);
  }
  return (
    <Tooltip
      classes={{
        tooltip: "sample-details-tooltip",
      }}
      onOpen={afterShow}
      enterDelay={300}
      leaveDelay={300}
      title={function () {
        if (loading) {
          return `Loading${dotsString}`;
        }
        return SampleDetailsFields(sample)
      }()}
    >
      {children}
    </Tooltip>
  );
}

export default SampleDetails;


export const SampleDetailsFields = (data: Partial<SampleDetailsType> | null) => (
  <div className="sample-details-container">
    {DetailsFields(data).map((section, index) => (
      <div key={index} className="sample-details-section">
        <Typography variant="h5" paragraph={false} className="sample-details-section-header">
          {section.title}
        </Typography>
        <div className="sample-details-content">
          {section.fields.map((detail, index) => (
            <React.Fragment key={index}>
              <span>{detail.label}:</span>
              <span>{detail.value}</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export const DetailsFields = (data: Partial<SampleDetailsType> | null) => {
  if (!data) return [];
  return ([
    {
      title: 'Sample information',
      fields: [
        {label: 'Sample name', value: data['sample original name']},
        {label: 'Alternate name', value: data['alternate name']},
        {label: 'Section name', value: data['section name']},
        {label: 'Height/depth (m)', value: data['height/depth']},
        {label: 'State, Country', value: [data['state/province'], data['country']].filter((x) => !!x).join(', ')},
        {label: 'Site description', value: data['site description']},
        {label: 'Latitude', value: data['site latitude']},
        {label: 'Longitude', value: data['site longitude']},
        {label: 'Sample notes', value: data['sample notes']}
      ],
    },
    {
      title: 'Geological context',
      fields: [
        {label: 'Craton/terrane', value: data['craton/terrane']},
        {label: 'Basin name', value: data['basin name']},
        {label: 'Basin type', value: data['basin type']},
        {label: 'Metamorphic bin', value: data['metamorphic bin']},
        {label: 'Environmental bin', value: data['environmental bin']},
        {label: 'Stratigraphic unit', value: data['stratigraphic unit']},
        {label: 'Geological Age', value: data['geological age']},
        {label: 'Interpreted age (Ma)', value: data['interpreted age']},
        {label: 'Minimum Age', value: data['min age']},
        {label: 'Maximum Age', value: data['max age']},
        {label: 'Interpreted age notes', value: data['interpreted age justification']},
        {
          label: 'Lithology',
          value: [data['color shade'], data['color'], data['lithology texture'], data['lithology composition'], data['lithology name']].filter((x) => !!x).join(' ')
        },
        {label: 'Lithology notes', value: data['lithology notes']},
        {label: 'Verbatim stratigraphic unit', value: data['verbatim stratigraphic unit']}
      ],
    },
    {
      title: 'Collection information',
      fields: [
        {label: 'Project name', value: data['project name']},
        {label: 'Collected by', value: data['collector name']},
        {label: 'Geological context provided by', value: data.geol_context_provided_by},
        {label: 'Interpreted age provided by', value: data.interpreted_age_provided_by}
      ],
    },
  ]);
}
