import React from 'react';

import { useSearchContext } from './store';
import TopPanel from '../TopPanel';
import SideBar from './SideBar';
import MapComponent from '../MapComponent';

import Table from './Table/Table';
import './../../styles/Search.css';

import Notifier from './Notifier/Notifier';
import Loader from '../Loader';
import { MapProvider } from '../MapComponent/store';

const Search = () => {
  const {
    searchStore: {
      mapDataLoading,
      loading,
      openMap,
    },
  } = useSearchContext();

  return (
    <>
      <div id='outer-container'>
        <TopPanel />
        <SideBar />
        <div id='page-wrap'>

          {openMap
            ? <MapProvider lazy>
              <MapComponent />
            </MapProvider>
            : <Table />
          }
          <Loader loading={loading || mapDataLoading} />
        </div>
      </div>
      <Notifier />
    </>
  );
};

export default Search;
