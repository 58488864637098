import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {QueryListOfTypes, QueryTypes} from "./constants";
import { useSearchContext } from '../SearchWithProvider/store';
import AsyncMultiInfoLabel from '../AsyncMultiInfoLabel';
import toolTips from '../toolTips';


const TypeSelector = () => {
  const {
    searchStore: {
      tabType,
    },
    callAPI: {
      changeType,
      setDefineParams,
      clearAll
    }
  } = useSearchContext()
  
  const handleChange = (_: React.MouseEvent<HTMLElement>, value: QueryListOfTypes) => {
    clearAll();
    changeType(value);
    if (value !== QueryTypes.EASY_SEARCH) {
      setDefineParams();
    }
  }
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      color='primary'
      classes={{
        root: classes.root
      }}
      exclusive={true}
      onChange={handleChange}
      value={tabType}
      size="small"
    >
      <ToggleButton value={QueryTypes.EASY_SEARCH}><AsyncMultiInfoLabel label="Simple Search" description={toolTips.simple_search}/></ToggleButton>
      <ToggleButton value={QueryTypes.SAMPLES}><AsyncMultiInfoLabel label="Detailed Search" description={toolTips.detailed_search}/></ToggleButton>
      <ToggleButton value={QueryTypes.ANALYSES}><AsyncMultiInfoLabel label="Analyses" description={toolTips.analytes_search}/></ToggleButton>
    </ToggleButtonGroup>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    padding: '10px 0px'
  }
}))

export default TypeSelector;
