import { Typography } from '@mui/material';
import React from 'react';
import '../../styles/Documentation.css';

const IndigenousDataGovernance = () => (
  <div className="documentation-page">
    <Typography variant="h4" className="documentation-contents-header">
      Indigenous Data Governance
    </Typography>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <Typography variant="body2" style={{ flex: 1 }}>
        SGP acknowledges that many of the lands and waters where we live, where
        geological samples are collected, and where geochemical measurements are
        conducted are traditional homelands and territories of Indigenous
        People. We recognize the continued significance of these lands and
        waters for Indigenous Peoples since time immemorial and are cognizant of
        the need for proper stewardship of data therein.
      </Typography>
      <Typography variant="body2" style={{ flex: 1 }}>
        Stemming from geosciences' extractive nature, Indigenous data has been
        collected in many places without prior informed consent. We are
        actively working to involve U.S. Native Nations in stewardship of their
        data from their lands and to implement CARE principles of Indigenous
        data governance more broadly. SGP is committed to developing new modes
        of collaboration, engagement, and partnership with Indigenous peoples
        for the care and stewardship of past and future heritage collections.
      </Typography>
      <Typography variant="body2" style={{ flex: 1 }}>
        The Indigenous homeland or traditional territory of samples in the SGP
        database can be displayed in the data table and included in data
        downloads via Show -{'>'} Samples Context and clicking the ‘traditional
        territory’ button. These are based on the current API call to Native
        Lands Digital (see{' '}
        <a href="https://native-land.ca" target="_blank">https://native-land.ca</a> for more
        information). Note that special characters display correctly in the
        website data table but do not display correctly if downloaded as a .csv
        and opened in Excel. To correctly display in Excel, navigate to Data&nbsp;
        {'>'} Import External Data {'>'} Import Data/Import From Text and choose
        65001 Unicode (UTF-8) (Excel uses an ANSI encoding).
      </Typography>
      <Typography variant="body2" style={{ flex: 1 }}>
        Please contact Erik Sperling {'( '}<a href="mailto:esper@stanford.edu">esper@stanford.edu</a> {')'} to discuss management
        and/or stewardship of Indigenous data.
      </Typography>
    </div>
  </div>
);
export default IndigenousDataGovernance;
