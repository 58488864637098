import axios from "axios";
import { useState } from "react";
import { SampleDetailsType } from "../types";

export const showFields = [
	'alternate_name',
	'section_name',
	'height_meters',
	'site_type',
	'state_province',
	'country',
	'site_desc',
	'coord_lat',
	'coord_long',
	'sample_notes',

	'craton_terrane',
	'basin_name',
	'basin_type',
	'meta_bin',
	'environment_bin',
	'strat_unit',
	'geol_age',
	'interpreted_age',
	'min_age',
	'max_age',
	'interpreted_age_notes',
	'color_shade',
	'color_name',
	'lithology_text',
	'lithology_comp',
	'lithology_name',
	'lithology_notes',
	'verb_strat_unit',

	'project_name',
	'collector',
	'geol_context_provided_by',
	'interpreted_age_provided_by',
];

type State = {
    sample: SampleDetailsType | null,
    loading: boolean,
    loadingDots: number,
}

const DEFAULT_STATE: State = {
    sample: null,
    loading: false,
    loadingDots: 0,
};

export const useSampleDetails = (): [State, (id: number) => Promise<void>] => {
  const [state, setState] = useState<State>(DEFAULT_STATE);

	const loadData = async (sampleId: number) => {
        if (!sampleId) return;
        setState({ ...DEFAULT_STATE, loading: true });

        const interval = setInterval(() => {
            setState(prevState => {
                let loadingDots = prevState.loadingDots + 1;
                if (loadingDots > 3) {
                    loadingDots = 0;
                }
                return { ...prevState, loadingDots };
            })
        }, 200);

        const query = {
            type: 'samples',
            filters: {
                sample_id: sampleId
            },
            show: showFields
        };

        try {
            const res = await axios
                .post<SampleDetailsType[]>("/api/v1/post", query);

            const sample = res.data[0];

            setState({
                loading: false,
                loadingDots: 0,
                sample,
            });
        } finally {
            clearInterval(interval);
        }
    }
    return [state, loadData];
};
