import React, { } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import "../styles/Select.css"

type Props = {
  label?: string,
  description: JSX.Element | string,
}

const AsyncMultiInfoLabel = ({ label, description }: Props) => {
  if(!label && !description) return null;
  return (
    <div className="description">
      {label}
      {!!description && (
        <Tooltip
          title={description}
          arrow={true}
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default AsyncMultiInfoLabel;
