import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef, MRT_RowVirtualizer,
} from 'material-react-table';
import { TableMapResponseData } from '../../types';
import RowDetails from '../RowDetails';
import NavigationPoint from '../NavigationPoint';


const CustomTable = ({ data, clearCluster }: { data: TableMapResponseData[], clearCluster: () => void }) => {

  const columns = useMemo<MRT_ColumnDef<TableMapResponseData>[]>(
    () => [
      {
        accessorKey: 'stratigraphic unit',
        header: 'Stratigraphic Unit',
        size: 150,
      },
      {
        accessorKey: 'sample original name',
        header: 'Sample Name',
        size: 150,
      },
      {
        accessorKey: 'lithology name',
        header: 'Lithology name',
        size: 150,
      },
      {
        accessorKey: 'section name',
        header: 'Section name',
        size: 150,
      },
      {
        accessorKey: 'interpreted age',
        header: 'Interpreted age',
        size: 120,
      },
      {
        accessorKey: 'navigation',
        header: '',
        grow: false, //don't allow this column to grow to fill in remaining space - new in v2.8
        size: 40,
        Cell: ({ row }) => <NavigationPoint item={row.original} />,
      },
    ],
    [],
  );
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
    return () => {
      clearCluster(); //needed only for case where one item opened
      table.resetExpanded();
    };
  }, []);

  const [pagination, setPagination] = useState({ pageSize: 100, pageIndex: 0 });

  const table = useMaterialReactTable({
    columns,
    data,
    enableSorting: false,
    enableCellActions: false,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableTopToolbar: false,
    enableColumnResizing: false,
    muiTableContainerProps: { sx: { maxHeight: '600px', minHeight: '200px' } },
    enableBottomToolbar: true,
    enableColumnActions: false,
    enablePagination: true,
    enableStickyHeader: true,
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
    state: { pagination, density: 'compact' },
    onPaginationChange: setPagination,
    enableRowVirtualization: false,
    enableExpanding: true,
    enableExpandAll: false,
    localization: {
      expand: ''
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        row.toggleExpanded();
      },
      sx: { cursor: 'pointer' },
      paddingTop: 0,
      paddingBottom: 0
    }),
    muiDetailPanelProps: () => ({
      sx: { cursor: 'auto' },
      onClick: (e) => e.stopPropagation(),
    }),
    renderDetailPanel: ({ row }) =>
      <RowDetails
        row={row.original}
        isExpanded={row.getIsExpanded()} />,
    muiTableBodyCellProps: () => ({
      sx: {
        paddingTop: 0,
        paddingBottom: 0
      },
    }),
  });

  useEffect(() => {
    if (data.length === 1) {
      table.getRow('0').toggleExpanded();
    }
  }, [data, table]);

  useEffect(() => {
    table.refs.tableContainerRef.current?.scrollTo(0, 0);
  }, [pagination]);


  return <MaterialReactTable table={table} />;
};

export default CustomTable;
