import FilterSection from "../../../FilterPanel/FilterSection";
import {QueryTypes} from "../../../FilterPanel/constants";
import CheckboxGroup from "../../../CheckboxGroup";
import {
  carbon,
  carbonate_proxy,
  customElements,
  defaultShow,
  defaultShowAnalysis, defaultShowMethodology, defaultShowReferences,
  iron_sulfur,
  metalIsotope,
  metalIsotopeTip,
  mineralogy_composition,
  nitrogen_phosphorous
} from "../../../attributeShow";
import * as React from "react";
import {useSearchContext} from "../../store";

const ShowSection = () => {
  const {
    searchStore: {
      query: {
        show,
        type,
      },
      tabType
    }, callAPI: {
      changeShow
    }
  } = useSearchContext()
  const isChecked = (key: string) => {
    return show.includes(key);
  }
  return (
    <FilterSection title="Show">
      {(type === "samples" || tabType === QueryTypes.EASY_SEARCH) && (
        <>
          <CheckboxGroup
            colWidth={200}
            title="Iron and Sulfur"
            data={iron_sulfur}
            isChecked={isChecked}
            onChange={changeShow}
          />
          <CheckboxGroup
            colWidth={200}
            title="Carbon"
            data={carbon}
            isChecked={isChecked}
            onChange={changeShow}
          />
          <CheckboxGroup
            colWidth={200}
            title="Carbonate Proxies"
            data={carbonate_proxy}
            isChecked={isChecked}
            onChange={changeShow}
          />
          {/* <CheckboxGroup
            colWidth={200}
            title="Sulfur"
            data={sulfur}
            isChecked={isChecked}
            onChange={changeShow}
          /> */}
          <CheckboxGroup
            title="Nitrogen and Phosphorus"
            colWidth={200}
            data={nitrogen_phosphorous}
            isChecked={isChecked}
            onChange={changeShow}
          />
          <CheckboxGroup
            colWidth={200}
            title="Metal Isotopes"
            data={metalIsotope}
            isChecked={isChecked}
            onChange={changeShow}
            topTip={metalIsotopeTip}
          />
          <CheckboxGroup
            colWidth={130}
            title="Elements"
            data={customElements}
            isChecked={isChecked}
            onChange={changeShow}
          />
          <CheckboxGroup
            colWidth={200}
            title="Mineralogy/Composition"
            data={mineralogy_composition}
            isChecked={isChecked}
            onChange={changeShow}
          />
        </>
      )}
      <CheckboxGroup
        title="Samples Context"
        colWidth={200}
        data={defaultShow}
        isChecked={isChecked}
        onChange={changeShow}
      />
      {tabType !== QueryTypes.EASY_SEARCH && (
        <CheckboxGroup
          title="Methodology"
          data={type === "analyses" ? defaultShowAnalysis : defaultShowMethodology}
          isChecked={isChecked}
          onChange={changeShow}
        />
      )}
      <CheckboxGroup
        title="References"
        data={defaultShowReferences}
        isChecked={isChecked}
        onChange={changeShow}
      />
    </FilterSection>
  )
}
export default ShowSection
