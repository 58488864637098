import React, {useMemo} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ReactComponent as ApiLogo} from './../images/api-logo.svg';
import {ReactComponent as Map} from './../images/globe.svg';
import {Download, MenuOutlined} from "@mui/icons-material";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {omit} from 'lodash'
import {useSearchContext} from "./SearchWithProvider/store";
import "./../styles/SidePanel.css";
const TopPanel = () => {
  const {
    searchStore: {
      query,
      downloading,
      mapDownloading
    }, callAPI: {
      toggleMap,
      toggleDrawer,
      download
    }
  } = useSearchContext()
  const parsedQuery = useMemo(
    () =>
      `${JSON.stringify(omit(query, ['page', 'count', 'sorted']))}`,
    [query]);
  return (
    <div className="sidenav">
      <IconButton
        className="sidebar-button"
        onClick={toggleDrawer}
      >
        <MenuOutlined htmlColor="#fff"/>
      </IconButton>
      <div>
        <IconButton
          className="sidebar-button"
          onClick={() => toggleMap(false)}
        >
          <ListAltIcon htmlColor="#fff" fontSize="medium" />
          <span>Data Table</span>
        </IconButton>
        <Tooltip title={parsedQuery}>
          <IconButton
            className="sidebar-button"
          >
            <CopyToClipboard
              text={parsedQuery}
            >
              <ApiLogo color="#fff"/>
            </CopyToClipboard>
          </IconButton>
        </Tooltip>
        <IconButton 
          className="sidebar-button" 
          onClick={download} 
          disabled={downloading}

        >
          {downloading ? (
            <CircularProgress
              size={24}
            />
          ) : (
            <Download htmlColor="#fff" fontSize="large"/>
          )}
          <span>Download</span>
        </IconButton>
        <IconButton 
          className="sidebar-button"
          disabled={mapDownloading} 
          onClick={() => toggleMap(true)}
        >
          {mapDownloading ? (
            <CircularProgress
              size={24}
            />
          ) : (
            <Map color="#fff" />
          )}
          <span>Map</span>
        </IconButton>
      </div>
    </div>
  );
}

export default TopPanel;
