import React from 'react';
import { Link } from "@mui/material";
import { NavLink as RouterLink } from 'react-router-dom';
import "../../styles/Documentation.css";

const Navigation = () => {
  return (
    <div className="documentation-nav-bar">
      <span className="documentation-nav-bar-header">Documentation</span>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation" exact={true}>
        Home Page
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/license-and-citation" exact={true}>
        License and Citation
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/web-site" exact={true}>
        Web Site
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/api-description" exact={true}>
        Public API Description
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/search-attributes" exact={true}>
        Search Attributes Dictionary
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/video" exact={true}>
        Video Tutorial
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/proxy-primers" exact={true}>
        Proxy Primers
      </Link>
      <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/indigenous-data-governance" exact={true}>
        Indigenous Data Governance
      </Link>
      
    </div>
  );
}

export default Navigation;
