import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CustomTable from './MapTable';
import { TableMapResponseData } from '../types';
import { useMapContext } from '../store';


export type MapDialogProps = {
  clusterData?: TableMapResponseData[];
  clearCluster: () => void
};

const Transition = React.forwardRef<HTMLDivElement, SlideProps>((props, ref) => {
  return <Slide ref={ref} direction='down' {...props} >{props.children}</Slide>;
});

const MapDialog: FC<MapDialogProps> = ({ clusterData, clearCluster }) => {
  const { openClusterDialog, modalAPI: { closeModal  } } = useMapContext();

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <Dialog
        open={openClusterDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth='xl'
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Samples</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 0 }}>
          {!!clusterData && clusterData.length > 0 &&
            <CustomTable data={clusterData} clearCluster={clearCluster} />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MapDialog;
