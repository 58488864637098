import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Feature from 'ol/Feature.js';
import Map from 'ol/Map';
import { useStyles } from './styles';
import {
  GenerateClusterLayer,
  MapRasterSource,
  MapVectorSource,
  generateFeatures,
  printMap,
  getDataFromFeatures,
} from './common';
import VectorSource from 'ol/source/Vector';
import { View } from 'ol';
import { Attribution, FullScreen, defaults as defaultControls } from 'ol/control';

import Legend from './Legend';
import { useSearchContext } from '../SearchWithProvider/store';
import { MapResponseData, TableMapResponseData } from './types';
import MapDialog from './Dialog';
import { fromLonLat } from 'ol/proj';
import { useMapContext } from './store';


const MapComponent = () => {
  const { searchStore: { mapData, mapDataLoading } } = useSearchContext();
  const { modalAPI: {
    closeModal,
    openModal
  }, legendAPI: {
    closeLegend
  }, selectedPoint,
  } = useMapContext()
  const classes = useStyles();
  const mapElement = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();
  const [selectedClusterData, setSelectedCluster] = useState<TableMapResponseData[] | undefined>(undefined);
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    if (mapDataLoading) return;
    if (!mapData.length) {
      setFeatures([]);
      return;
    }
    fetchFeatures(mapData);
  }, [mapData]);


  useEffect(() => {
      if (!selectedPoint) return;
      closeModal();
      const targetLocation = fromLonLat(selectedPoint);
      if (mapRef.current) {
        mapRef.current.getView().animate({ center: targetLocation, zoom: 10, duration: 1000 });
      }
    },
    [mapRef, selectedPoint],
  );

  useLayoutEffect(() => {
    if (!mapElement.current) return;
    const initialMap = new Map({
      target: mapElement.current,
      view: new View({
        center: [0, 0],
        zoom: 1,
      }),
      controls: defaultControls({
        rotate: false,
        attribution: false,
      }).extend([
        new FullScreen(),
        new Attribution({ collapsible: false }),
      ]),
    });

    initialMap.setLayers([MapRasterSource, GenerateClusterLayer(MapVectorSource)]);

    mapRef.current = initialMap;

    mapRef.current?.on('click', function(evt) {
      if (!mapRef.current) return;
      const feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, function(feature) {
        return feature;
      });
      if (!feature) return;

      closeLegend()
      const featuresData = feature.get('features');
      openModal();
      setSelectedCluster(getDataFromFeatures(featuresData));
    });

    mapRef.current?.on('pointermove', (e: any) => {
      if (!mapRef.current) return;
      const pixel = mapRef.current.getEventPixel(e.originalEvent);
      const hit = mapRef.current.hasFeatureAtPixel(pixel);
      const target = mapRef.current.getTarget();
      if (target instanceof HTMLElement) {
        target.style.cursor = hit ? 'pointer' : '';
      }
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.setTarget(undefined);
      }
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const source = new VectorSource({
      wrapX: false,
      features,
    });
    mapRef.current.setLayers([MapRasterSource, GenerateClusterLayer(source)]);
  }, [features]);

  const fetchFeatures = async (data: MapResponseData[]) => {
    setFeatures(generateFeatures(data));
  };

  const clearCluster = () => {
    // needed for rerender table with new data after opening  cluster with one item
    setSelectedCluster(undefined)
  }

  const onPrintClick = useCallback(() => {
    if (!mapRef.current) {
      return;
    }
    printMap(mapRef.current);
  }, []);

  return (
    <div className={classes.root}>
      <MapDialog clusterData={selectedClusterData}  clearCluster={clearCluster} />
      <div className={classes.mapWrapper}>
        <div ref={mapElement} className={classes.mapContainer}>
        </div>
        <a id='image-download' download='map.png'></a>
      </div>
      <Legend printMap={onPrintClick} />
    </div>
  );
};

export default MapComponent;
