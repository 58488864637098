import React, { FC } from "react";
import FilterSection from "../../../FilterPanel/FilterSection";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { EasySearchTypesEnum, EasySearchTypesTooltipEnum, easySearchTypeValue } from "../../../FilterPanel/constants";
import MinMaxRangeSelect from "../../../MaxMinRangeSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSearchContext } from "../../store";
import Dropdown from "../../../../common/components/Dropdown";
import AsyncMulti from "../../../AsyncMulti";
import AsyncMultiInfoLabel from "src/components/AsyncMultiInfoLabel";
import { Grid } from "@mui/material";

interface EasySearchProps {
  variant: EasySearchTypesEnum
  onChangeVariant: (even: React.SyntheticEvent) => void
}

const EasySearchSection: FC<EasySearchProps> = ({ variant, onChangeVariant }) => {
  const {
    searchStore: {
      query,
    },
    callAPI: {
      constructRange,
      constructMulti
    }
  } = useSearchContext()

  return (
    <FilterSection title="">
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 1 }}>
        <FormControl>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {easySearchTypeValue.map((label, i) => (
              <React.Fragment key={i}>
                <FormControlLabel
                  key={label}
                  sx={{ mx: 1, marginLeft: i ? undefined : 0 }}
                  value={label}
                  onChange={onChangeVariant}
                  control={<Checkbox checked={label === variant} />}
                  label={<Grid container alignItems="center">{label}<AsyncMultiInfoLabel description={EasySearchTypesTooltipEnum[label]}/></Grid>
                }
                />
                
              </React.Fragment>
            ))}
          </div>
          <MinMaxRangeSelect
            constructRange={constructRange}
            attribute="interpreted_age"
            query={query}
            title="Interpreted Age in Ma:"
          />
        </FormControl>
      </Box>
      <Dropdown title="Collection Site" defaultOpen={true}>
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="country"
          label="Country"
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="state_province"
          label="State/Province"
          params={{ countries: query.filters.country }}
        />
      </Dropdown>
    </FilterSection>
  )
}
export default EasySearchSection
