import React, { useState, useEffect } from "react";
import { Table as MuiTable, TableHead, TableBody, TableCell, TableRow, Collapse } from "@mui/material";
import TableCellText from "./TableCellText";
import { Columns } from "../SearchWithProvider/types";

type Props = {
    className?: string,
    row: any,
    columns: Columns[],
};

const TableCustomRow = ({
    className,
    row,
    columns
}: Props) => {
    const [state, setState] = useState<any>({});

    useEffect(() => {
        setState({});
    }, [row]);

    return (
        <>
            <TableRow key={row.id} className={`data-row ${className}`}>
                {columns.map((col: any) => (
                    <TableCell
                        key={`${row.id}_${col.accessor}`}
                        className={col.className}
                    >
                        {col.renderCell
                            ? col.renderCell({ row, state, setState })
                            : (
                                <TableCellText>
                                    {row[col.accessor]}
                                </TableCellText>
                            )}
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
            {!!row.subRows?.length && (
                <TableRow className="sub-table">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 32, outline: 0, border: 0 }} colSpan={10000}>
                        <Collapse in={state.open} timeout="auto" unmountOnExit>
                            <MuiTable size="small" style={{ marginBottom: 16 }}>
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(row.subRows[0]).map((x: any) => (
                                        <TableCell key={x}>
                                            {x}
                                        </TableCell>
                                        ))}
                                        <TableCell width="100%" />
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                        {row.subRows.map((row: any, i: number) => (
                                            <TableRow key={i} className={i % 2 > 0 ? 'odd-row' : 'even-row'}>
                                                {Object.values(row).map((v: any) => (
                                                    <TableCell component="th" scope="row">
                                                        {v}
                                                    </TableCell>
                                                ))}
                                                <TableCell width="100%" />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                            </MuiTable>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default TableCustomRow;
