import { Link, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as CCBYImage } from '../../images/cc-by.svg';
import "../../styles/Documentation.css";

const LicenseAndCitation = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">License and Citation</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
            <Typography variant='body2' style={{ flex: 1 }}>
                The SGP Phase 2 data product is freely available under a <Link href="https://creativecommons.org/licenses/by/4.0/?ref=chooser-v1" target="_blank">CC BY 4.0 license</Link>.
                Users downloading and re-using this data in publications should cite Farrell et al., 2021, Geobiology, as the source.
                This paper can be accessed&nbsp;
                <Link href="https://onlinelibrary.wiley.com/doi/full/10.1111/gbi.12462" target="_blank" title="The Sedimentary Geochemistry and Paleoenvironments Project">here</Link>.&nbsp;
                We are currently in the process of publishing a new citable reference describing the Phase 2 product.
            </Typography>
            <Link href="https://onlinelibrary.wiley.com/doi/full/10.1111/gbi.12462" target="_blank">
                <CCBYImage />
            </Link>
        </div>
    </div>
);
export default LicenseAndCitation;