import React, { useMemo, ReactElement } from "react";
import RangeSelect from "./RangeSelect";
import "../styles/Select.css"
import {QueryType, StringArrayFilters} from "./SearchWithProvider/types";

interface Attributes {
  [key: string]: string;
}

interface Props {
  constructRange: (min: number | null, max: number | null, attribute: string) => void
  attributes: Attributes;
  query: QueryType;
}

const RangeList: React.FC<Props> = ({ constructRange, attributes, query }: Props): ReactElement => {
  const entries = useMemo(() => Object.entries(attributes), [attributes]);
  return (
    <div className="range-select-root">
      {entries.map((item, idx) => (
        <RangeSelect
          key={idx}
          constructRange={constructRange}
          attribute={item[0] as keyof StringArrayFilters}
          title={item[1]}
          query={query}
        />
      ))}
    </div>
  );
}

export default RangeList;
