import React from "react";
import { Logo } from "./Utils";

import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const NoRecords = () => {
  const classes = useStyles();
  return (
    <div className={classes.noRecordsOverlay}>
      <Logo />
      <Typography variant="body1">
        No data
      </Typography>
      <Typography variant="caption">
        Try to change filters and search again
      </Typography>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  noRecordsOverlay: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default NoRecords;
