import React, { useMemo } from "react";
import { Dictionary } from 'lodash';
import Dropdown from "../common/components/Dropdown";
import { Checkbox, CheckboxProps, FormControlLabel, Grid, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import AsyncMultiInfoLabel from "./AsyncMultiInfoLabel";
import { AttributesShowTooltip } from "./attributeShow";

type Props = {
  title: string;
  data: Dictionary<string>;
  isChecked: (value: string) => boolean;
  onChange: (key: string, label: string) => void;
  colWidth?: number;
  topTip?: {
    label: string;
    description: JSX.Element | string;
  }
}

const CheckboxGroup = ({ title, data, isChecked, onChange, colWidth, topTip }: Props) => {
  const entries = useMemo(() => Object.entries(data), [data]);

  const handleChange: CheckboxProps["onChange"] = (e) => {
    onChange(e.target.value, data[e.target.value]);
  }

  const classes = useStyles({ colWidth });

  return (
    <Dropdown title={title}>
      {topTip && <AsyncMultiInfoLabel label={topTip.label} description={topTip?.description} />}
      <div className={classes.dropdown}>
      {entries.map(([key, label]) => (<React.Fragment key={key}>
        <Grid container alignItems="baseline">
          <FormControlLabel
            key={key}
            label={label}
            classes={{
              label: classes.label
            }}
            control={(
              <Checkbox
                classes={{
                  root: classes.checkbox
                }}
                size="small"
                value={key}
                checked={isChecked(key)}
                onChange={handleChange}
              />
            )}
          
          /> 
          <AsyncMultiInfoLabel
            description={AttributesShowTooltip[key as keyof typeof AttributesShowTooltip]}
          />
        </Grid>
      </React.Fragment>))}
      </div>
    </Dropdown>
  )
}

type StyleProps = {
  colWidth?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dropdown: ({ colWidth }) => ({
    display: "grid",
    gridTemplateColumns: colWidth 
      ? `repeat(auto-fill, minmax(${colWidth}px, 1fr))` 
      : undefined,
    // gap: theme.spacing(0.5)
  }),
  checkbox: {
    padding: '0px 9px'
  },
  label: {
    wordBreak: "break-word",
  }
}))

export default CheckboxGroup;