import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import "./../styles/JumbotronUI.css";
import Video from './../images/video.mp4';
import { Button } from "@mui/material";
import Counters from "./Counters";
import TypedText from "../common/components/TypedText";
import { get, includes } from "lodash";
import { Phase2Alert } from "./Phase2Alert";
import { useStoredValue } from "src/common/hooks/useStoredValue";
import { CONFIG_CONSTANTS } from "src/constants";

interface ResponseData {
  count: number;
}

const JumbotronUI: React.FC<RouteComponentProps> = (props) => {
  const [results, setResults] = useState<number | undefined>(undefined);
  const [samples, setSamples] = useState<number | undefined>(undefined);
  const [countries, setCountries] = useState<number | undefined>(undefined);
  const [phase2AlertOpen, setPhase2AlertOpen] = useState(false);
  const [showPhase2Alert, setShowPhase2Alert] = useStoredValue('SGP.userSetting.showPhase2Alert', true);

  const typingText = "Sedimentary Geochemistry and Paleoenvironments Project";

  useEffect(() => {
    Promise.all([
      getInfoSamples(),
      getInfoResults(),
      getInfoCountries(),
    ]);
  }, []);

  const getInfoSamples = async () => {
    try {
      const response: AxiosResponse<ResponseData[]> = await axios.get("/api/v1/get/info/samples");
      setSamples(Number(response.data[0].count));
    } catch (error: any) {
      console.log(error);
    }
  }

  const getInfoResults = async () => {
    try {
      const response: AxiosResponse<ResponseData[]> = await axios.get("/api/v1/get/info/results");
      setResults(Number(response.data[0].count));
    } catch (error: any) {
      console.log(error);
    }
  }

  const getInfoCountries = async () => {
    try {
      const response: AxiosResponse<ResponseData[]> = await axios.get("/api/v1/get/info/countries");
      setCountries(response.data[0].count);
    } catch (error: any) {
      console.log(error);
    }
  }

  const onSearchClick = () => {
    const { history } = props;
    if (!showPhase2Alert || !CONFIG_CONSTANTS.SHOW_PHASE2_ALERT) {
      history.push("/search");
      return;
    }
    setPhase2AlertOpen(true);
  }

  const onPhase2AlertClose = () => {
    const { history } = props;
    setPhase2AlertOpen(false);
    history.push("/search");
  }

  const onDontShowAgainPhase2Alert = () => {
    setShowPhase2Alert(false);
    setPhase2AlertOpen(false);
    history.push("/search");
  }

  const { history } = props;
  const pathname = get(history, 'location.pathname');
  const routesToShow = ['/', '/about', '/about/',];
  const visible = includes(routesToShow, pathname);
  const routesToShowSearch = ['/'];
  const searchVisible = includes(routesToShowSearch, pathname);

  return (
    <div hidden={!visible}>
      <div className="jumbotron">
        <video className="video-background" autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        <div className="jumbotron-content">
          <TypedText textClassName="typing" text={typingText} />
          {searchVisible && (
            <Button
              className="search-button"
              variant="contained"
              size="large"
              onClick={onSearchClick}
            >
              Search
            </Button>
          )}
        </div>
      </div>
      <Counters
        countries={countries}
        samples={samples}
        results={results}
      />
      <Phase2Alert
        open={phase2AlertOpen}
        onClose={onPhase2AlertClose}
        onDontShowAgain={onDontShowAgainPhase2Alert}
      />
    </div>
  );
}

export default withRouter(JumbotronUI);
