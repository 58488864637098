import { Link, Typography } from "@mui/material";
import React from "react";
import "../../styles/About.css";

type Props = {
  src: string;
  alt: string;
  name: string;
  education: string;
  link?: string;
}

const PhotoThumbnail = ({ src, alt, name, education, link }: Props) => {
  return (
    <div className="thumbnail">
      <div className="thumbnail-image-wrapper">
        <img src={src} alt={alt} />
      </div>
      <Typography variant="h5" className="text-content">
        {!!link
          ? (
            <Link href={link}>{name}</Link>
          ) : name}
      </Typography>
      <Typography variant="body2" className="text-content">{education}</Typography>
    </div>
  );
}

export default PhotoThumbnail;
