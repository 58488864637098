import Link from '@mui/material/Link';
import React from "react";
import "./../styles/Menu.css";

type Props = {
  className: string;
  name: string;
  href: string;
}

const Menu = ({ className, name, href }: Props) => (
  <div className={className}>
    <Link href={href || "/"}>{name?.toUpperCase()}</Link>
  </div>

);

export default Menu;
