import { Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const LINKS = [
    { title: "SGP Proxy Primer: Strontium isotopes (Dr. Graham Shields and Dr. Ying Zhou)", url: "https://www.youtube.com/embed/kVmntRYaap8?si=mfE6HMGvlfy9aL5S"},
    { title: "SGP Proxy Primer: Bayesian approaches to stratigraphy (Tianran Zhang, Dr. Kilian Eichenseer, Dr. Martin Smith)", url: "https://www.youtube.com/embed/UEKZVNur99M?si=2rXyl14x_6_arz-y"},
    { title: "SGP Proxy Primer: Uranium isotopes (Dr. Kim Lau)", url: "https://www.youtube.com/embed/tg7Zf6hjaMI?si=xpZp7TtOcA0HYe0b"},
    { title: "SGP Proxy Primer: Cerium anomalies (Dr. Rosalie Tostevin)", url: "https://www.youtube.com/embed/TQ8lkd_w1qI?si=N2v_njAXU68zGqkg"},
    { title: "SGP Proxy Primer: Programmed pyrolysis (Rock-Eval) (Dr. Alex Zumberge)", url: "https://www.youtube.com/embed/7yz0gi-rXL4?si=OlHulNz3Y5I12Fed"},
    { title: "SGP Proxy Primer: Analyzing large geochemical datasets (Dr. Akshay Mehra and Dr. Kurt Sundell)", url: "https://www.youtube.com/embed/muMFS5rR-IY?si=UNeCksXcs06EMOyM"},
    { title: "SGP Proxy Primer: Carbon isotopes (Dr. Selva Marroquin)", url: "https://www.youtube.com/embed/xXdYWXP6hxU" },
    { title: "SGP Proxy Primer: Sulfur isotopes (Dr. Maya Gomes)", url: "https://youtube.com/embed/5nbf9JtEVlU"},
    { title: "SGP Proxy Primer: I/Ca redox proxy (Dr. Zunli Lu)", url: "https://youtube.com/embed/wkHys_KG91A"},
    { title: "SGP Proxy Primer: Re-Os geochronology and Os isotopes (Dr. Alan Rooney)", url: "https://www.youtube.com/embed/pFRMgyohXbo" },
    { title: "SGP Proxy Primer: Nitrogen isotopes (Dr. Eva Stüeken)", url: "https://www.youtube.com/embed/2cNkWSPqMo4" },
]

const ProxyPrimers = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Proxy Primers</Typography>
        <div>
            <Typography variant="body2" paragraph={true}>
                The SGP Proxy Primers aim to accessibly explain the basics of a geochemical proxy or method to a broad audience of geologists, geochemists, and paleontologists. The goal is to help our community learn the promise, methodology, and caveats of a given proxy. The live talks are recorded for our YouTube channel; SGP team members have an opportunity to informally ask questions of the expert in an unrecorded Q&A session following each presentation.
            </Typography>
            <div className="videos-container">
                {LINKS.map((link, idx) => (
                    <div key={idx}>
                        <div className="video-title">{link.title}</div>
                        <div className="videos-container-wrapper">
                            <iframe src={link.url} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);
export default ProxyPrimers;