import React, { lazy, Suspense, useEffect } from 'react';
import { TableMapResponseData } from '../types';
import { Box, LinearProgress } from '@mui/material';
import { useMapContext } from '../store';


const Details = lazy(() => import('./MapTable/PointDetails'));

const RowDetails = ({ row, isExpanded }: {
  isExpanded: boolean,
  row: TableMapResponseData,
}) => {
  const {
    callAPI: { handleNavigationClick, loadData },
    mapStore: { loading, mapData }
  } = useMapContext();

  const handleNavigation = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleNavigationClick(row);
  };

  useEffect(() => {
    if (isExpanded && !Object.keys(mapData).includes(`${row['sample identifier']}`)) {
      loadData(Number(row['sample identifier']));
    }
  }, [row['sample identifier'], isExpanded]);

  return (
    <Box width={940} minHeight={450} style={{ overflow: 'auto', margin: 'auto' }}>
      {loading && <LinearProgress />}
      <Suspense fallback={<LinearProgress />}>
        <Details item={mapData[row['sample identifier']]} handleNavigation={handleNavigation} />
      </Suspense>
    </Box>
  );
};


export default RowDetails;







