import Dropdown from "../../../../common/components/Dropdown";
import RangeList from "../../../RangeList";
import { carbon, carbonate_proxy, customElements, iron_sulfur, metalIsotope, mineralogy_composition, nitrogen_phosphorous } from "../../../attributeShow";
import FilterSection from "../../../FilterPanel/FilterSection";
import * as React from "react";
import { useSearchContext } from "../../store";
import AsyncMulti from "src/components/AsyncMulti";
import { QueryTypes } from "src/components/FilterPanel/constants";

const AnalyteFiltersSections = () => {
  const {
    searchStore: {
      query
    },
    callAPI: {
      constructRange,
      constructMulti,
    }
  } = useSearchContext()
  return (
    <FilterSection title="Analyte Filters">
      {query.type === QueryTypes.SAMPLES && (
        <>
          <Dropdown title="Iron and Sulfur">
            <RangeList
              constructRange={constructRange}
              attributes={iron_sulfur}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Carbon">
            <RangeList
              constructRange={constructRange}
              attributes={carbon}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Carbonate Proxies">
            <RangeList
              constructRange={constructRange}
              attributes={carbonate_proxy}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Nitrogen and Phosphorus">
            <RangeList
              constructRange={constructRange}
              attributes={nitrogen_phosphorous}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Metal Isotopes">
            <RangeList
              constructRange={constructRange}
              attributes={metalIsotope}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Elements">
            <RangeList
              constructRange={constructRange}
              attributes={customElements}
              query={query}
            />
          </Dropdown>
          <Dropdown title="Mineralogy/Composition">
            <RangeList
              constructRange={constructRange}
              attributes={mineralogy_composition}
              query={query}
            />
          </Dropdown>
        </>
      )}
      <Dropdown title="Methodology">
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="geochem_method"
          label="Prep Method"
          enableSelectAll
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="experiment_method_code"
          label="Experimental Method Code"
          enableSelectAll
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="experiment_method"
          label="Experimental Method Translation"
          enableSelectAll
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="analytical_method_code"
          label="Analytical Method Code"
          enableSelectAll
        />
        <AsyncMulti
          onChange={constructMulti}
          query={query}
          attribute="analytical_method"
          label="Analytical Method Translation"
          enableSelectAll
        />
        {query.type === QueryTypes.ANALYSES && (
          <>
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lab"
              label="Lab"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="provider"
              label="Provided By"
            />
          </>
        )}
      </Dropdown>
    </FilterSection>
  )
}
export default AnalyteFiltersSections
