import React from "react";
import { CircularProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

type Props = {
    loading: boolean;
    onClosing?: () => void;
};

const ENTER_INTERVAL = 250;
const EXIT_INTERVAL = 250;

const Loader = ({
    loading,
    onClosing
}: Props) => {
    const classes = useStyles();

    return (
        <CSSTransition
            in={loading}
            timeout={{ enter: ENTER_INTERVAL, exit: ENTER_INTERVAL }}
            onExiting={onClosing}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={clsx(classes.loadingContainer, 'loading-container')}
        >
            <div className={classes.loadingContainer}>
                <div className="loader-content">
                    <CircularProgress />
                    <Typography variant="caption">
                        Loading
                    </Typography>
                </div>
            </div>
        </CSSTransition>
    );
}

const useStyles = makeStyles<Theme>((theme) => ({
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#ffffffcf",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',

        '&.loading-container-enter': {
            opacity: 0,
        },

        '&.loading-container-enter-active': {
            opacity: 1,
            transition: `opacity ${ENTER_INTERVAL}ms ease-in`,
        },
        '&.loading-container-enter-done': {
            opacity: 1,
        },
        '&.loading-container-exit-active': {
            opacity: 0,
            transition: `opacity ${EXIT_INTERVAL}ms ease-out`,
        },

        '& .loader-content': {
            opacity: 0,

            display: 'flex',
            flexDirection: "column",
            gap: theme.spacing(1),
            justifyContent: 'center',
            alignItems: 'center',
        },

        '&.loading-container-enter-done > .loader-content': {
            transition: `opacity 500ms ease-in`,
            opacity: 1,
        },
    }
}))

export default Loader;
