import React, { useState, useEffect } from 'react';
import { AppBar } from "@mui/material";
import Menu from "./Menu";
import SGPLogo from './../images/sgp_logo.png';
import "./../styles/NavBar.css";
import { mergeClassNames } from "src/common/funcs/mergeClassNames";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import {RouteComponentProps} from "react-router";

interface CustomNavBarProps {
  className?: string;
}

const CustomNavBar: React.FC<CustomNavBarProps & RouteComponentProps> = ({ className, location }) => {
  const pathname = get(location, 'pathname', '');
  const v2 = pathname.startsWith('/documentation') || pathname.startsWith('/map');
  const position = ['/search'].includes(pathname) ? 'static' : 'absolute';

  const [stickyNavClass, setStickyNavClass] = useState('');
  const [itemClass, setItemClass] = useState('nav-bar-item');

  useEffect(() => {
    let newStickyNavClass = mergeClassNames(className, 'sticky-nav');
    let newItemClass = 'nav-bar-item';
    if (v2) {
      newStickyNavClass = mergeClassNames(newStickyNavClass, 'sticky-nav-v2');
      newItemClass = mergeClassNames(newItemClass, 'nav-bar-item-v2');
    }
    if (position === 'static') {
      newStickyNavClass = mergeClassNames(newStickyNavClass, 'nav-bar-static');
    }
    setStickyNavClass(newStickyNavClass);
    setItemClass(newItemClass);
  }, [className, v2, position]);

  return (
    <AppBar className={stickyNavClass} position={position}>
      <a href="/">
        <img
          src={SGPLogo}
          width="150"
          height="75"
          alt="logo"
          className="navbar-icon"
        />
      </a>
      <div className="nav-items">
        <Menu name="home" className={itemClass} href="/"/>
        <Menu name="documentation" className={itemClass} href="/documentation" />
        <Menu name="about" className={itemClass} href="/about" />
      </div>
    </AppBar>
  );
}

export default withRouter(CustomNavBar);
