
import axios from "axios";
import { MapResponseData } from "../MapComponent/types";
import { QueryType } from "../SearchWithProvider/types";

const BASE_API = "api"

export const getMapData = async (query: QueryType) => {
	return await axios.post<MapResponseData[]>(`${BASE_API}/frontend/map`, query)
}

export const getTableData = async (query: QueryType) => {
	return axios.post("api/frontend/post-paged", {
		...query,
		page: query.page + 1
	});
}
