import Feature, {FeatureLike} from "ol/Feature";
import {Geometry, Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import {Cluster, OSM} from "ol/source";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import gradientAges from "./gradientAges.json";
import colorString from "color-string";
import {Map} from "ol";
import {defaultBgColor, noAgeBgColor} from "./styles";
import {fromLonLat} from 'ol/proj';
import { MapResponseData, TableMapResponseData } from './types';
import TileLayer from 'ol/layer/Tile';
import maxBy from "lodash/maxBy"
import groupBy from "lodash/groupBy"

export const MapVectorSource = new VectorSource({
  wrapX: false,
  features: [],
});

export const MapRasterSource = new TileLayer({
  source: new OSM({
    crossOrigin: '',
    wrapX: true,
    interpolate: true,
  }),
});

export function getDataFromFeatures(featuresData: Feature[]): TableMapResponseData[] {
	return featuresData.map((feature) => feature.get('data'));
}

export function generateFeatures(data: MapResponseData[]){
    let colorBoofer: string | null = null;
    return data.map(
		(item, index, array): Feature<Geometry> => {
			if(!item['site latitude'] || !item['site longitude']) return new Feature();
			if (!item['interpreted age']) colorBoofer = noAgeBgColor;
			if (item['interpreted age'] === "0.00") colorBoofer = gradientAges[0].color;
			if (!colorBoofer || (index >= 1 && array[index - 1]['interpreted age'] !== item['interpreted age'])) colorBoofer = getAgeColor(Number(item['interpreted age']))?.color || defaultBgColor;
			return new Feature({
				'geometry': new Point(fromLonLat([Number(item['site longitude']), Number(item['site latitude'])])),
				'data': {...item, color: colorBoofer},
			});
		}
	);
}

export function getColorForFeature(features: Feature<Geometry>[]) {
	const groupByColor = groupBy(features, (el) => el.get('data').color);
	if(Object.keys(groupByColor).length > 1)
		delete groupByColor[noAgeBgColor];
	const maxColor = maxBy(Object.keys(groupByColor), (el) => groupByColor[el].length);

	return maxColor ?? defaultBgColor;
}
export function setFeatureStyle(feature: FeatureLike) {
	const color = feature.get('features')[0].get('data').color;
	var svg = '<svg width="120" height="120" version="1.1" xmlns="http://www.w3.org/2000/svg">'
		+ `<circle cx="60" cy="60" r="60" fill="${color}"/>`
		+ '</svg>';
	return new Style({
		image: new Icon({
			src: 'data:image/svg+xml;utf8,' + svg,
			scale: 0.1
		}),
	})
}

function darkenRGB(rgb: string, amount = 0.7): string {
	const matches = rgb.match(/\d+/g);
	if (!matches) {
		console.error("Invalid RGB format");
		return rgb;
	}

	let [r, g, b] = matches.map(Number);
	r = Math.max(Math.floor(r * amount), 0);
	g = Math.max(Math.floor(g * amount), 0);
	b = Math.max(Math.floor(b * amount), 0);
	// Возвращаем утемненный цвет в формате RGB
	return `rgb(${r}, ${g}, ${b})`;
}

export const getTextColor = (color: string) => {
	let rgbColor = colorString.get.rgb(color);
	let luminance = (0.299 * rgbColor[0] + 0.587 * rgbColor[1] + 0.114 * rgbColor[2]) / 255;
	if (luminance > 0.2)
		return "#000"; // bright colors - black font
	else
		return "#fff" // dark colors - white font
}

const CLUSTERS_DISTANCE = 40;
const MIN_CLUSTER_SIZE = 10;
const MAX_CLUSTER_SIZE = 20;
const CLUSTER_SIZE_RATIO = 0.01;
const CLUSTER_SIZE_ZOOM_RATIO = 1;

// ol library related constant.
const MIN_ZOOM = 3;

export function GenerateClusterLayer(source: VectorSource<Feature<Geometry>>) {
	return new VectorLayer({
		source: new Cluster({
			distance: CLUSTERS_DISTANCE,
			minDistance: 1,
			source: source,
			wrapX: true
		}),
		style: function (feature) {
			const features: Feature<Geometry>[] = feature.get('features');
			const size = features.length;
			if (size === 1) {
				return setFeatureStyle(feature);
			}
			const color = getColorForFeature(features);
			const zoomRatio = Math.max(CLUSTER_SIZE_ZOOM_RATIO - MIN_ZOOM, 1);
			const clusterSize = MIN_CLUSTER_SIZE + zoomRatio + Math.min(size * CLUSTER_SIZE_RATIO * zoomRatio, MAX_CLUSTER_SIZE - MIN_CLUSTER_SIZE)
			return new Style({
				image: new CircleStyle({
					radius: clusterSize,
					stroke: new Stroke({
						color: darkenRGB(color, 0.7),
						width: 1,
					}),
					fill: new Fill({
						color: color.replace('rgb', 'rgba').replace(')', ', 0.5)'),
					}),
				}),
				text: new Text({
					text: size.toString(),
					fill: new Fill({
						color: getTextColor(color).replace('rgb', 'rgba').replace(')', ', 0.5)'),
					}),
					overflow: true,
				}),
				zIndex: MAX_CLUSTER_SIZE - clusterSize
			});
		},
	});
}


export const getAgeColor = (interpretedAge: number) => gradientAges.find((item, index) =>
	interpretedAge >= item.age && interpretedAge < gradientAges[index + 1]?.age
);


export function printMap(map: Map){
	map?.once('rendercomplete', function () {
		const mapCanvas = document.createElement('canvas');
		const size = map.getSize();
		mapCanvas.width = size ? size[0] : 500;
		mapCanvas.height = size ? size[1] : 500;
		const mapContext = mapCanvas.getContext('2d') || undefined;
		Array.prototype.forEach.call(map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'), function (canvas) {
			if (canvas.width > 0 && !!mapContext) {
				const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
				let matrix;
				const transform = canvas.style.transform;
				if (transform) {
					matrix = transform
						.match(/^matrix\(([^\(]*)\)$/)[1]
						.split(',')
						.map(Number);
				} else {
					matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
				}
				CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
				const backgroundColor = canvas.parentNode.style.backgroundColor;
				if (backgroundColor) {
					mapContext.fillStyle = backgroundColor;
					mapContext.fillRect(0, 0, canvas.width, canvas.height);
				}
				mapContext.drawImage(canvas, 0, 0);
			}
		});
		if (!!mapContext) {
			mapContext.globalAlpha = 1;
			mapContext.setTransform(1, 0, 0, 1, 0, 0);
		}
		const link = document.getElementById('image-download') as HTMLAnchorElement;
		link.href = mapCanvas.toDataURL();
		link.click();
	});
	map?.renderSync();
}
