import React, { useState, useCallback, useEffect } from 'react';
import {
  DEFAULT_SORTED,
  defaultEasySearchShow,
  defaultSearchState,
  EasySearchTypesEnum,
  HIDE_ALERT_TIMEOUT,
  QueryListOfTypes,
  QueryTypes,
} from "../FilterPanel/constants";
import { difference, keys, get, intersection, isNumber } from 'lodash';
import { saveAs } from 'file-saver';
import {
  carbon,
  metalIsotope,
  customElements,
  defaultShowReferences,
  iron_sulfur,
  carbonate_proxy,
  nitrogen_phosphorous,
} from '../attributeShow';
import { SearchContext, Props, SearchState, FiltersType, QueryType } from "./types";
import axios, { AxiosError } from "axios";
import { getMapData, getTableData } from "../Api/api";
import { RouteComponentProps } from "react-router";
import createCtx from '../../common/createCtx';

const [useCtx, Provider] = createCtx<SearchContext>();

function SearchProvider(props: Props & RouteComponentProps): React.ReactElement {
  const [searchStore, setSearchState] = useState<SearchState>(defaultSearchState);

  const postSearch = useCallback(async (query: QueryType) => {
    setSearchState(prevState => ({ ...prevState, loading: !searchStore.openMap }));
    try {
      const response = await getTableData(query)
      setSearchState((prevState) => ({
        ...prevState,
        data: response.data.rows,
        column: [{
          columns: Object.keys(response.data.rows[0] || {}).filter(x => x !== 'subRows')
            .map(key => ({
              Header: key,
              accessor: key
            }))
        }],
        totalPages: Math.ceil(response.data.count / query.count),
        totalCount: response.data.count,
        loading: false,
      }));
    } catch (error) {
      const e = error as AxiosError;
      let errorMessage = 'An error occurred during the search request';
      if (e.response) {
        errorMessage = e.response.data ? e.response.data : errorMessage;
      }
      setSearchState(prevState => ({
        ...prevState,
        loading: false,
        error: errorMessage
      }));
    }
  }, [searchStore.query]);

  useEffect(() => {
    setSearchState((prevState) => ({
      ...prevState,
      drawerOpen: true,
    }));
  }, []);

  const changeType = (value: QueryListOfTypes) => {
    if (!value) return;
    setSearchState((prevState) => {
      let query = { ...prevState.query };
      let show = query.show;
      let type = value;

      if (value === QueryTypes.EASY_SEARCH) {
        show = defaultEasySearchShow;
        type = QueryTypes.SAMPLES

      } else {
        show = []
      }

      return {
        ...prevState,
        query: {
          ...query,
          type,
          show,
          filters: {},
          sorted: DEFAULT_SORTED
        },
        tabType: value
      };
    });
  };
  const changeShow = (key: string, label: string) => {
    setSearchState(prevState => {
      const showIndex = prevState.query.show.indexOf(key);
      const newShow = showIndex !== -1
        ? prevState.query.show.filter(item => item !== key)
        : [...prevState.query.show, key];
      return {
        ...prevState,
        query: {
          ...prevState.query,
          show: newShow,
          ...(prevState.query.sorted[0].id === label && { sorted: DEFAULT_SORTED })
        }
      };
    });
  };
  const constructMulti = useCallback((attribute: keyof FiltersType, arr) => {
    setSearchState(prevState => {
      let query = { ...prevState.query };
      if (arr.length) {
        query.filters[attribute] = arr.map((option: { value: string }) => option.value);
      } else {
        delete query.filters[attribute];
      }


      return { ...prevState, query };
    });
  }, []);
  const constructEasySearchMode = useCallback((mode: EasySearchTypesEnum) => {
    setSearchState(prevState => {
      const newFilters = {...prevState.query.filters};
      if (mode === EasySearchTypesEnum.SHALES) {
        delete newFilters.is_carbonate;
        newFilters.is_shale = true;
      }
      if (mode === EasySearchTypesEnum.CARBONATES) {
        newFilters.is_carbonate = true;
        delete newFilters.is_shale;
      }
      let query = {
        ...prevState.query,
        filters: newFilters
      }
      return { ...prevState, query };
    });
  }, []);
  const constructRange = useCallback((min, max, attribute) => {
    setSearchState(prevState => {
      let query = { ...prevState.query } as any; //todo need typing

      let numberMin = min;
      if (!isNumber(numberMin)) {
        numberMin = null;
      }
      let numberMax = max;
      if (!isNumber(numberMax)) {
        numberMax = null;
      }

      if (numberMin === null && numberMax === null) {
        delete query.filters[attribute];
      } else {
        query.filters[attribute] = [numberMin, numberMax];
      }

      return { ...prevState, query };
    });
  }, []);

  const loadMapData = async () => {
    setSearchState(prevState => ({ ...prevState, mapDataLoading: true }));
    try {
      const { data } = await getMapData(searchStore.query);
      setSearchState(prevState => ({
        ...prevState,
        mapData: data,
        mapDataLoading: false
      }));
    } catch (error) {
      console.error("Map error:", error);
      setSearchState(prevState => ({
        ...prevState,
        mapDataLoading: false,
        error: 'Error on downloading map data'
      }));
    }
  };
  const onSortedChange = useCallback(async (id: string, desc: boolean) => {
    setSearchState(prevState => ({ ...prevState, query: { ...prevState.query, sorted: [{ id, desc }] } }));
    await postSearch({ ...searchStore.query, sorted: [{ id, desc }] });
  }, [postSearch]);

  const setDefineParams = () => {
    setSearchState(prevState => {
      const { interpreted_age, is_carbonate, is_shale, ...filters } = prevState.query.filters;
      return {
        ...prevState,
        query: {
          ...prevState.query,
          filters
        }
      };
    });
  };
  const clearShow = useCallback((show, type) => {
    const defaultValues = Object.keys(defaultShowReferences);
    let removeReferences = true;

    if (type === QueryTypes.ANALYSES) {
      removeReferences = false;
    } else {
      const sectionsToCheck = [
        iron_sulfur, carbon, carbonate_proxy, nitrogen_phosphorous, metalIsotope, customElements
      ];
      for (const section of sectionsToCheck) {
        if (intersection(show, keys(section)).length) {
          removeReferences = false;
        }
      }
    }

    if (removeReferences) {
      return difference(show, defaultValues);
    }

    return show;
  }, []);
  const clearAll = () => {
    let show: string[] = []
    if (searchStore.tabType === QueryTypes.EASY_SEARCH) {
      show = defaultEasySearchShow;
    }
    setSearchState(prevState => ({
      ...prevState,
      data: [],
      query: {
        ...prevState.query,
        filters: {},
        show,
        sorted: DEFAULT_SORTED
      },
      mapData: []
    }));
  };
  const download = useCallback(async () => {
    setSearchState((prevState) => ({ ...prevState, downloading: true }));
    try {
      const show = clearShow(searchStore.query.show, searchStore.query.type);

      const response = await axios.post("api/frontend/csv", {
        ...searchStore.query,
        show,
        page: searchStore.query.page + 1
      }, { responseType: 'blob' });

      saveAs(response.data, 'SGP.csv');

      setSearchState((prevState) => ({ ...prevState, downloading: false }));
    } catch (e) {
      let error = 'An error occurred during the download request';

      const responseData = get(e, 'response.data');
      if (responseData) {
        error = responseData;
      }

      setSearchState((prevState) => ({
        ...prevState,
        downloading: false,
        error,
      }));

      setTimeout(() => {
        setSearchState((prevState) => ({ ...prevState, error: '' }));
      }, HIDE_ALERT_TIMEOUT);
    }
  }, [searchStore.query]);

  const toggleDrawer = useCallback(() => {
    setSearchState(prevState => ({
      ...prevState,
      drawerOpen: !prevState.drawerOpen
    }));
  }, []);
  const toggleMap = (state: boolean) => {
    setSearchState(prevState => ({
      ...prevState,
      openMap: state
    }));
    if (!searchStore.mapData.length && state) {
      loadMapData();
    }
  };
  const closeDrawer = useCallback(() => {
    setSearchState(prevState => ({
      ...prevState,
      drawerOpen: false
    }));
  }, []);
  const onSearchPress = async () => {
    setSearchState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        sorted: DEFAULT_SORTED,
        page: 0,
      },
      mapData: []
    }));
    if (searchStore.openMap) {
      await loadMapData().catch(error => console.warn("Search or map data loading failed:", error)); // order is important
    }
    await postSearch({ ...searchStore.query, sorted: DEFAULT_SORTED, page: 0 }); // order is important

  };

  const onTablePageSizeChange = useCallback(async (count) => {
    setSearchState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        count,
        page: 0
      }
    }));
    await postSearch({ ...searchStore.query, count });
  }, [postSearch]);
  const clearError = () => {
    setSearchState(prevState => ({
      ...prevState,
      error: '',
    }));
  }
  const onTablePageChange = async (page: number) => {
    setSearchState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        page
      }
    }));
    await postSearch({ ...searchStore.query, page });
  };

  const callAPI = {
    changeType,
    changeShow,
    constructMulti,
    constructEasySearchMode,
    constructRange,
    onTablePageChange,
    onTablePageSizeChange,
    onSearchPress,
    loadMapData,
    onSortedChange,
    setDefineParams,
    clearShow,
    postSearch,
    clearAll,
    download,
    toggleDrawer,
    toggleMap,
    clearError,
    closeDrawer,
  }

  return (
    <Provider
      value={{
        searchStore,
        callAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useSearchContext, SearchProvider };
