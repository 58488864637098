import {Alert, IconButton, Snackbar} from "@mui/material";
import React from "react";
import {useSearchContext} from "../store";
import CloseIcon from '@mui/icons-material/Close';


const Notifier = () => {
  const {searchStore: {error}, callAPI: {clearError}} = useSearchContext()

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    clearError()
  };

  return (
    <Snackbar
      open={!!error}
      autoHideDuration={6000}
      onClick={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
        <Alert severity="error">
          {error}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small"/>
          </IconButton>
        </Alert>
    </Snackbar>
  )
}
export default Notifier
