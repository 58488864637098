import React, { useEffect } from "react";
import { Button, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  loading: boolean;
  onSearch: () => void;
  onClear: () => void;
}

const FilterActions = ({ loading, onSearch, onClear }: Props) => {
  const classes = useStyles();

  useEffect(() => {
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          onSearch();
        }
      }
      window.addEventListener('keydown', onKeyDown);
      return () => window.removeEventListener('keydown', onKeyDown);
    }, [onSearch]);

  return (
    <div className={classes.filterActionsContainer}>
      <Button
        variant="outlined"
        onClick={onClear}
      >
        Clear All
      </Button>
      <Button
        variant="contained"
        disabled={loading}
        onClick={onSearch}
        startIcon={loading && (
          <CircularProgress size={14} />
        )}
      >
        Search
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filterActionsContainer: {
    padding: theme.spacing(1),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignSelf: "flex-end",
    gap: "20px",
  },
}));

export default FilterActions;
