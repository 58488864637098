
const toolTips = {
  environment_bin:
    " Environments are divided into five bins - inner shelf/outer shelf/basinal/lacustrine/fluvial - 1-3 as defined in Sperling et al. 2015.",
  section_name:
    "This could be a drillcore, stratigraphic section, or spot-sampling locality. In some rare instances where complete information could be not obtained, a best-guess sampling locality was created (for instance, the geographic center of New York state if the only geographical information was 'New York State').",
  craton_terrane:
    "The name of the craton or terrane e.g. Laurentia, Avalonia. As entered by SGP collaborative team members",
  basin_name:
    "The name of the sedimentary basin e.g. Taconic Foreland Basin. As entered by SGP collaborative team members",
  basin_type: "The type of basin e.g. peripheral foreland, rift, etc.",
  metamorphic_bin:
    "Sites are sorted into three low-grade metamorphic bins, roughly based on metapelite zones as follows: 1) Diagenetic zone. Under mature, preserved biomarkers, KI>0.42, CAI ≤3, Ro <2.0, facies: zeolite-subgreenschist facies, grade: diagenesis-very low grade 2) Anchizone. Over-mature, no preserved biomarkers, CAI=4, Ro 2-4, facies: sub-greenschist, grade: very low grade 3) Epizone. Ro>4, CAI=5, KI<0.25, facies: greenschist, grade: low-grade. A minority of higher metamorphic grade samples in the database are coded as Amphibolite or Catazone.",
  geol_age: "International Commission on Stratigraphy age names",
  lithology_name: "The primary sediment type of the sample",
  lithology_text: "Textural modifiers (siltey, muddy, sandy, clayey)",
  lithology_comp:
    "Compositional modifiers (e.g., phosphatic, calcareous, dolomitic, etc.)",
  interpreted_age:
    "A numerical estimate for the age of the sample in millions of years",
  project_name:
    "Samples are grouped into projects ranging from individual published papers to informal lab projects. Samples may be associated with one or more projects.",
  data_source: "Data in the SGP database come from six data sources: 1) information provided by the SGP Collaborative Team, 2) legacy USGS data from the National Geochemical Database, 3) data compiled by the USGS Critical Metals In Black Shales project (CMIBS), 4) data from the Australian OZCHEM database, 5) data from the Alberta Geological Survey (AGS), 6) data compiled by the DM-SED project.",
  collector: "Samples may be associated with multiple attributes. If selected, this will cause the sample to appear multiple times in the results table.",
  simple_search: "Rapidly access data using queries based on geological age and geography. Standard sample information is pre-selected for convenience.",
  detailed_search: "Fine-tune queries, with filters on all sample/geography/geology/methodology information, plus the option to filter geochemical data by abundance.",
  analytes_search: "Explore individual geochemical analyses - this search does not average results, convert oxides to elements, or standardize units."
};

export default toolTips
