import React from "react";
import "../../styles/Search.css";
import {withRouter} from 'react-router-dom';

import {SearchProvider} from "./store";
import Search from "./Search";
import {RouteComponentProps} from "react-router";

const SearchComponent = (route: RouteComponentProps) => {

  return (
    <SearchProvider lazy {...route}>
      <Search/>
    </SearchProvider>
  );
}

export default withRouter(SearchComponent);

