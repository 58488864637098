import { AppBar, Button, Drawer, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import gradientAges from './gradientAges.json';
import { useLegendStyles, noAgeBgColor } from './styles';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import { getTextColor } from './common';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import clsx from 'clsx';
import { useMapContext } from './store';

type GradientAgeType = {
  age: number,
  color: string,
  name: string
}
type Props = {
  printMap: () => void
}

export default function Legend({ printMap }: Props) {
  const { legendOpen, legendAPI: { openLegend, closeLegend } } = useMapContext();
  const classes = useLegendStyles();
  const toggleLegend = useCallback(() =>
      !legendOpen
        ? openLegend()
        : closeLegend(),
    [legendOpen]);
  return (
    <div className={classes.mainWrapper}>
      {!legendOpen && (<>
        <Button
          className={clsx(classes.printButton, classes.mapButtonBGcolor)}
          variant='contained'
          onClick={printMap}
          sx={{ position: 'absolute' }}
        >
          <LocalPrintshopIcon style={{ marginRight: 8 }} /> Print Map
        </Button>
        <Button
          className={clsx(classes.legendButton, classes.mapButtonBGcolor)}
          variant='contained'
          onClick={toggleLegend}
          sx={{ position: 'absolute' }}
        >
          <ArrowCircleLeftIcon style={{ marginRight: 8 }} /> Legend
        </Button>
      </>)}
      <Drawer
        open={legendOpen}
        variant='persistent'
        anchor='right'
        elevation={1}
        PaperProps={{
          style: {
            position: 'absolute',
            height: '100%',
            width: '20vw',
            minWidth: '300px',
            paddingLeft: 150,
            border: 'none',
            backgroundColor: 'transparent',
            overflowX: 'hidden',
          },
        }}
        sx={{
          ':docked': {
            height: '100%',
          },
        }}
      >
        <AppBar
          position='static'
          className={classes.legendAppBar}
        >
          <Button
            className={classes.mapButtonBGcolor}
            variant='contained'
            onClick={printMap}
            style={{ position: 'absolute', left: 0, top: 10 }}
          >
            <LocalPrintshopIcon style={{ marginRight: 8 }} /> Print Map
          </Button>
          <Button className={classes.mapButtonBGcolor} variant='contained' onClick={toggleLegend}
                  style={{ position: 'absolute', left: 20, top: 60 }}>
            <ArrowCircleRight style={{ marginRight: 8 }} /> Legend
          </Button>
        </AppBar>
        <div className={classes.rowsWrapper}>
          {gradientAges.map((gradientAge: GradientAgeType, index) =>
            (
              <div
                key={index}
                className={classes.legendRow}
                style={{
                  backgroundColor: gradientAge.color,
                  color: getTextColor(gradientAge.color),
                }}
              >
                <Typography variant='subtitle1'>{gradientAge.age}</Typography>
                <Typography variant='subtitle1'>{gradientAge.name}</Typography>
              </div>
            ),
          )}
          <div
            className={classes.legendRow}
            style={{
              backgroundColor: noAgeBgColor,
              color: '#000',
            }}
          >
            <Typography variant='subtitle1'>-</Typography>
            <Typography variant='subtitle1'>No age data</Typography>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
