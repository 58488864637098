import React from "react";
import { Link, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./../styles/Search.css";

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography variant="h4" color="primary" className="text-header">Our Mission</Typography>
      <Typography variant="body2" paragraph={true} className="text-content">
        The Sedimentary Geochemistry and Paleoenvironments Project (SGP) 
        is a research consortium that seeks to address questions of environmental 
        evolution across Earth history through statistical analyses of the sedimentary 
        geochemical record. This search website allows you to search our Phase 2 data 
        product, which was made available in Winter 2025. If you are interested in 
        joining SGP please visit our informational website&nbsp;
        <Link href="https://sgp.stanford.edu" target="_blank" title="">here</Link>.
      </Typography>

      <Typography variant="body2" paragraph={true} className="text-content">
      The SGP wiki, containing information on our SQL database, how we code data, and the data in the Phase 1 and 2 data products, can be found&nbsp;
        <Link href="https://github.com/ufarrell/sgp_phase1/wiki" target="_blank" title="">here</Link>.
      </Typography>

      <Typography variant="body2" paragraph={true} className="text-content">
        <b>
        The Phase 2 data product is now openly available. Users downloading and re-using this data in publications should cite Farrell et al., 2021, Geobiology, in their methods sections and note they are using the Phase 2 data. This paper can be accessed&nbsp;
        <Link href="https://onlinelibrary.wiley.com/doi/full/10.1111/gbi.12462" target="_blank" title="The Sedimentary Geochemistry and Paleoenvironments Project">here</Link>.
        We are currently in the process of publishing a new citable reference describing the Phase 2 product.
        </b>
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((_theme: Theme) => ({
  content: {
    margin: 'auto',
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1025
  }
}))

export default Home;
