import IconButton from '@mui/material/IconButton';
import PlaceIcon from '@mui/icons-material/Place';
import React from 'react';
import { useMapContext } from '../store';
import { TableMapResponseData } from '../types';


const NavigationPoint = ({ item }: { item: TableMapResponseData }) => {
  const { callAPI: { handleNavigationClick } } = useMapContext();

  const handleClose = () => {
    handleNavigationClick(item);
  };

  return (
    <IconButton size='small' onClick={handleClose}>
      <PlaceIcon />
    </IconButton>
  );
};

export default React.memo(NavigationPoint);
