import React, { useCallback, useState } from 'react';
import { Props, Context, MapState, TableMapResponseData } from './types';
import axios from 'axios';
import { SampleDetailsType } from '../SearchWithProvider/types';
import { showFields } from '../SearchWithProvider/SampleDetails/common';
import createCtx from '../../common/createCtx';
import { Coordinate } from 'ol/coordinate';

const [useCtx, Provider] = createCtx<Context>();

const initialMapState: MapState = {
  mapData: [],
  loading: false,
};

function MapProvider(props: Props): React.ReactElement {
  const [mapStore, setMapState] = useState(initialMapState);
  const [openClusterDialog, setClusterDialogOpen] = useState(false);
  const [legendOpen, setLegendOpen] = useState(false);
  const [selectedPoint, setPoint] = useState<undefined | Coordinate>(undefined);

  const loadData = async (sampleId: number) => {
    if (!sampleId) return;
    setMapState(prev => ({ ...prev, loading: true }));
    const query = { type: 'samples', filters: { sample_id: sampleId }, show: showFields };
    try {
      const res = await axios.post<SampleDetailsType[]>('/api/v1/post', query);
      const sample = res.data[0];
      setMapState(prev => {
        const sampleIdentifier = sample['sample identifier'];
        if (!prev.mapData[sampleIdentifier]) {
          return { ...prev, loading: false, loadingDots: 0, mapData: { ...prev.mapData, [sampleIdentifier]: sample } };
        } else {
          return { ...prev, loading: false, loadingDots: 0 };
        }
      });
    } catch (error) {
      console.error(error);
      setMapState(prev => ({ ...prev, loading: false, loadingDots: 0 }));
    }
  };

  const getMapData = useCallback((sampleIdentifier: number) => {
    return mapStore.mapData[sampleIdentifier];
  }, []);

  const toggleClusterDialog = useCallback(
    () => setClusterDialogOpen((prev) => !prev),
    []);

  const closeModal = useCallback(() => setClusterDialogOpen(false), []);
  const openModal = useCallback(() => setClusterDialogOpen(true), []);

  const closeLegend = useCallback(() => setLegendOpen(false), []);
  const openLegend = useCallback(() => setLegendOpen(true), []);

  const handleNavigationClick = (item: TableMapResponseData) => {
    closeModal();
    setPoint([Number(item['site longitude']), Number(item['site latitude'])]);
  };

  const callAPI = {
    loadData,
    getMapData,
    handleNavigationClick,
  };
  const modalAPI = {
    toggleClusterDialog,
    closeModal,
    openModal,
  };
  const legendAPI = {
    closeLegend,
    openLegend,
  };
  const mapAPI = {
    setPoint,
  };

  return (
    <Provider
      value={{
        mapStore,
        openClusterDialog,
        legendOpen,
        selectedPoint,
        callAPI,
        modalAPI,
        legendAPI,
        mapAPI,
      }}
    >
      {props.children}
    </Provider>
  );
}

export { useCtx as useMapContext, MapProvider };
