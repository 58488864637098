
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const noAgeBgColor = "rgb(155, 155, 155)";
export const defaultBgColor = "rgb(51, 153, 204)";

export const useStyles = makeStyles(() => ({
    root: {
		flex: "1",
		display: "grid",
		gridTemplateColumns: "9fr 0fr",
		gridTemplateRows: "1fr",
		gridRowGap: "0px",
		gridArea: "1 / 2 / 2 / 3",
		position: 'relative'
	},
	mapWrapper: {
		height: '100%',
		position: 'relative'
	},
	mapContainer: {
		height: '100%',
		width: '100%',
		position: 'absolute',
		"& .ol-control button": {
			backgroundColor: "#1976d2",
			fontSize: "26px",
    		margin: 10,
			color: "#fff",
			border: "1px solid #ccc",
			borderRadius: 4,
			display: 'block',
			fontWeight: 300
		},
		"& .ol-overlaycontainer-stopevent .ol-control": {
			width: 0
		},
		"& .ol-attribution.ol-uncollapsible": {
			position: "absolute",
			bottom: 0,
			right: 0,
			width: "auto",
			padding: "0 7px",
			background: "rgba(255, 255, 255, 0.75)",
		},
		"& .ol-attribution button":{
			display: "none"
		}, 
		"& .ol-attribution ul": {
			listStyleType: "none",
			width: "auto",
			margin: 0,
			padding: 0,
			"& a": {
				color: "#000",
				textDecoration: "none"
			}
		},
		"& .ol-overlaycontainer-stopevent .ol-control button": {
			width: "36px !important",
			height: "36px !important",
			backgroundColor: "#7d90b8",
			fontSize: "25px",
			cursor: "pointer",
		},
		"& .ol-overlay-container":{
			display: "block !important"
		}
	},
	tooltip: {
		position: "absolute",
        backgroundColor: "#fff",
        boxShadow: "0 1px 4px rgba(0,0,0,0.2)",
        borderRadius: "10px",
        border: "1px solid #cccccc",
        bottom: "-1px",
        left: "0px",
		maxHeight: "200px",
		overflow: "auto",
	},
	preloader: {
		display: 'flex',
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%"
	},
	clustersPoint: {
		padding: "8px",
		cursor: "pointer",
		whiteSpace: "nowrap",
		'&:hover': {
			backgroundColor: "#e2e2e2"
		}
	}
}));


export const useLegendStyles = makeStyles((theme: Theme) => ({
	mainWrapper: {
		position: 'relative',
		height: '100%',
		width: '100%'
	},
	printButton: {
		position: 'absolute',
		right: 10,
		top: 10,
		whiteSpace: 'nowrap'
	},
	legendButton: {
		position: 'absolute',
		right: 10,
		top: 60
	},
	mapButtonBGcolor: {
		backgroundColor: "#7d90b8 !important"
	},
	rowsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	legendRow: {
		borderTop: "1px solid #000",
		borderLeft: "1px solid #000",
		padding: '0px 8px',
		height: "100%",
	},
	legendAppBar: {
		minWidth: '400px',
		width: '100%',
		color:  theme.palette.text.primary,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	}
}));
