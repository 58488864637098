import { Link, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CCImage } from '../images/cc.svg';
import { ReactComponent as BYImage } from '../images/by.svg';
import "./../styles/Footer.css";

const Footer = () => (
  <footer className="footer">
    <Typography variant="h6" paragraph={false}>
      © 2025 Sedimentary Geochemistry and Paleoenvironments Project
    </Typography>
    <Typography variant="h6" paragraph={false}>
      Driven and Developed by <Link href="https://aionis.io" target="_blank" fontWeight="600">Aionis</Link>
    </Typography>
    <Typography variant="h6" paragraph={false}>
      SGP Phase 2 data is licensed under&nbsp;
      <Link style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }} href="https://creativecommons.org/licenses/by/4.0/?ref=chooser-v1" target="_blank">CC BY 4.0
        <CCImage style={{ height: 20, width: 20 }} />
        <BYImage style={{ height: 20, width: 20 }} />
      </Link>
    </Typography>
  </footer>
);
export default Footer;
